import { Lang } from 'types/lang'

/**
 * エリアの繁体字データ
 */
export const TW_AREA_NAMES = {
  '北海道・東北エリア': '北海道/東北地區',
  関東エリア: '關東地區',
  中部エリア: '中部地區',
  関西エリア: '關西地區',
  中国エリア: '中國地區',
  四国エリア: '四國地區',
  '九州・沖縄エリア': '九州/沖繩地區',
}

/**
 * エリア名を取得する
 * @param locale
 * @param prefecture
 * @returns
 */
export const getAreaName = (locale: string, prefecture: any): string => {
  let areaName: string = ''

  switch (locale) {
    case 'ja':
      areaName = prefecture.area_name.replace('エリア', '')
      break
    case 'en':
      areaName = prefecture.en_area_name.replace('Area', '')
      break
    case 'zh_tw':
      areaName = TW_AREA_NAMES[prefecture.area_name].replace('地區', '')
      break
    default:
      areaName = prefecture.en_area_name.replace('Area', '')
      break
  }
  return areaName
}

/**
 * 言語一覧を取得する
 * @returns Lang[] 言語一覧
 */
export function getLangs(): Lang[] {
  return [
    {
      slug: 'ja',
      name: '日本語',
    },
    {
      slug: 'en',
      name: 'English',
    },
    {
      slug: 'zh-TW',
      name: '繁體中文',
    },
  ]
}

/**
 * 選択した言語キーを条件に言語データを取得する
 * @param locale string 選択した言語のキー
 * @returns Lang 選択した言語のデータ
 */
export function getLangByLocale(locale: string): Lang {
  const langs: Lang[] = getLangs()

  let lang: Lang = langs[0]
  langs.map((l: Lang) => {
    if (l.slug === locale) {
      lang = l
    }
  })

  return lang
}

/**
 * localeを置換する
 * @param locale
 * @returns
 */
export function replaceLocale(locale: string): string {
  return locale.replace('zh_tw', 'zh-TW')
}
